import { LOGOUT_M } from '@/graphql/mutations'
import { initialAbility } from '@/libs/acl/config'

export default {
  methods: {
    logout() {
      this.$apollo.mutate({
        mutation: LOGOUT_M,
      }).then(res => {
        this.$store.commit('auth/setLoggedOut')
        this.$ability.update(initialAbility)
        this.$router.push({ name: 'login' }).then(() => {
          this.$store.commit('auth/clearAuthState')
        })
      }).catch(error => {
        this.$store.commit('auth/setLoggedOut')
        this.$ability.update(initialAbility)
        this.$router.push({ name: 'login' }).then(() => {
          this.$store.commit('auth/clearAuthState')
        })
      })
    },
  },
}
