<template>
<!--  <p class="clearfix mb-0 mx-0 px-0">-->
<!--    <span class="float-md-left d-block d-md-inline-block mt-25">-->
<!--      {{ $t('copyright') }}  © {{ new Date().getFullYear() }}-->
<!--      <b-link-->
<!--        class="ml-25"-->
<!--        href="https://lenon.app"-->
<!--        target="_blank"-->
<!--      >Lenon</b-link>-->
<!--      <span class="d-none d-sm-inline-block">, {{ $t('copyright_reserved') }}</span>-->
<!--    </span>-->

<!--    <span class="float-md-right d-none d-md-block">Powered By Amot-->
<!--      <feather-icon-->
<!--        icon="HeartIcon"-->
<!--        size="21"-->
<!--        class="text-primary"-->
<!--      />-->
<!--    </span>-->
<!--  </p>-->
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
