<template>
  <div class="navbar-container full-width d-flex justify-content-between">
    <div class="bookmark-wrapper d-flex align-items-center flex-grow-1">
      <!--        School Logo Info-->
      <template v-if="!isSideBarHidden || isHome">
        <lenon-profile-photo
          v-if="logoUrl"
          size="40"
          :photo="logoUrl"
        />
        <b-avatar
          v-else
          size="40"
          variant="light-primary"
        />
      </template>
      <!--        Mobile Side Bar Toggler-->
      <b-avatar
        v-else
        size="40"
        variant="light-primary"
        style="cursor:pointer;"
        @click.native="toggleSideMenu()"
      >
        <feather-icon icon="AlignJustifyIcon" />
      </b-avatar>

      <div class="ml-1" />
      <div
        v-responsive="$store.getters['app/largeScreen']"
        class="d-flex flex-column justify-content-start"
      >
        <strong style="font-size: 15px">{{ appBarData.school_name }} <span class="text-primary"> {{ currentModule?'-':'' }} {{ $t(currentModule) }}</span></strong>
        <label
          style="font-weight: bold;color: orangered;"
        >{{ appBarData.has_academic_year?appBarData.title:'Please setup Academic Year !' }}</label>
      </div>
      <div
        v-responsive="$store.getters['app/smallScreen']"
        class="d-flex flex-column justify-content-start"
      >
        <strong style="font-size: 15px">{{ appBarData.alias?appBarData.alias.toUpperCase():"N/A" }}</strong>
        <label
          style="font-weight: bold;color: orangered;"
        >{{ appBarData.has_academic_year?appBarData.short_title:'Setup Academic Year!' }}</label>
      </div>
    </div>
    <b-navbar-nav class="nav align-items-center">
      <div
        v-responsive="$store.getters['app/mediumScreen']"
        class="d-flex flex-column align-items-end"
        style="padding-right: 3px;"
      >
        <strong>{{ appBarData.first_name }}</strong>
        <label style="font-size: 11px;">{{ appBarData.role }}</label>
      </div>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <lenon-profile-photo
            size="40"
            :photo="photo"
            :preview="false"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name:'profile'})"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          v-if="hasMultipleTerms"
          link-class="d-flex align-items-center"
          @click="$router.push({name:'active-terms'})"
        >
          <feather-icon
            size="16"
            icon="RepeatIcon"
            class="mr-50"
          />
          <span>Switch Term</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="hasMultipleTerms" />
        <b-dropdown-item
          v-if="hasMultipleAccounts"
          link-class="d-flex align-items-center"
          @click="$router.push({name:'active-accounts'})"
        >
          <feather-icon
            size="16"
            icon="RefreshCwIcon"
            class="mr-50"
          />
          <span>Switch Account</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="hasMultipleAccounts" />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="changeTheme"
        >
          <feather-icon
            v-if="!isDarkMode"
            size="16"
            icon="MoonIcon"
            class="mr-50"
          />
          <span v-if="!isDarkMode">Dark Mode</span>

          <feather-icon
            v-if="isDarkMode"
            size="16"
            icon="SunIcon"
            class="mr-50"
          />
          <span v-if="isDarkMode">Light Mode</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormCheckbox,
} from 'bootstrap-vue'
import logoutMixin from '@/lenon/mixins/logoutMixin'
import { $themeConfig } from '/themeConfig'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  components: {
    LenonProfilePhoto,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormCheckbox,
  },
  mixins: [logoutMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isDarkMode: false,
    }
  },
  computed: {
    isSideBarHidden() {
      return $themeConfig.layout.isSideBarHidden
    },
    isHome() {
      return this.$route.name === 'home'
    },
    photo() {
      return this.$store.getters['auth/userProfile']
    },
    logoUrl() {
      return this.$store.getters['auth/schoolLogo']
    },
    appBarData() {
      return this.$store.getters['auth/appBarData']
    },
    currentModule() {
      let title = this.$store.getters['auth/currentModule']
      if (this.$route.name === 'home') {
        title = ''
      }
      return title
    },
    hasMultipleTerms() {
      return this.$store.getters['auth/hasMultipleTerms']
    },
    hasMultipleAccounts() {
      return this.$store.getters['auth/hasMultipleAccounts']
    },
  },
  watch: {
    isDarkMode(darkmode) {
      let skin = 'light'
      if (darkmode) {
        skin = 'dark'
      }
      this.$store.commit('appConfig/UPDATE_SKIN', skin)
      if (skin === 'dark') {
        const body = document.getElementById('lenon-body-light')
        if (body) {
          body.setAttribute('id', 'lenon-body-dark')
        }
      } else {
        const body = document.getElementById('lenon-body-dark')
        if (body) {
          body.setAttribute('id', 'lenon-body-light')
        }
      }
    },
  },
  mounted() {
    const skin = localStorage.getItem('vuexy-skin')
    this.isDarkMode = skin === 'dark'
  },
  methods: {
    changeTheme() {
      this.isDarkMode = !this.isDarkMode
    },
    toggleSideMenu() {
      if (document.getElementsByClassName('sidenav-overlay')[0].classList.contains('show')) {
        document.getElementsByClassName('sidenav-overlay')[0]
          .setAttribute('class', 'sidenav-overlay')
      } else {
        document.getElementsByClassName('sidenav-overlay')[0]
          .setAttribute('class', 'sidenav-overlay show')
      }
      if (document.getElementsByClassName('vertical-layout')[0].classList.contains('menu-hide')) {
        document.getElementsByClassName('vertical-layout')[0]
          .setAttribute('class', 'vertical-layout h-100 vertical-overlay-menu menu-open navbar-floating footer-static')
      } else {
        document.getElementsByClassName('vertical-layout')[0]
          .setAttribute('class', '  vertical-layout h-100 vertical-overlay-menu menu-hide navbar-floating footer-static')
      }
    },
  },
}
</script>
<!--<template>-->
<!--  <div class="navbar-container d-flex align-items-center full-width">-->

<!--    &lt;!&ndash; Left Col &ndash;&gt;-->
<!--    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">-->
<!--      <template v-if="!isSideBarHidden || isHome">-->
<!--        <lenon-profile-photo-->
<!--            v-if="logoUrl"-->
<!--            size="40"-->
<!--            :photo="logoUrl"-->
<!--        />-->
<!--        <b-avatar-->
<!--            v-else-->
<!--            size="40"-->
<!--            variant="light-primary"-->
<!--        />-->
<!--      </template>-->
<!--      <b-avatar-->
<!--          v-else-->
<!--          size="40"-->
<!--          variant="light-primary"-->
<!--          style="cursor:pointer;"-->
<!--          @click.native="toggleSideMenu()"-->
<!--      >-->
<!--        <feather-icon icon="AlignJustifyIcon" />-->
<!--      </b-avatar>-->
<!--      <div class="ml-1" />-->
<!--      <div class="d-flex flex-column justify-content-start">-->
<!--        <strong style="font-size: 15px">{{ appBarData.school_name }} <span class="text-primary"> {{ currentModule?'-':'' }} {{ $t(currentModule) }}</span></strong>-->
<!--        <label-->
<!--            style="font-weight: bold;color: orangered;"-->
<!--        >{{ appBarData.has_academic_year?appBarData.title:'Please setup Academic Year !' }}</label>-->
<!--      </div>-->
<!--    </div>-->
<!--    <b-navbar-nav class="nav align-items-center ml-auto">-->
<!--      <div-->
<!--          class="d-flex flex-column align-items-end"-->
<!--          style="padding-right: 3px;"-->
<!--      >-->
<!--        <strong>{{ appBarData.first_name }}</strong>-->
<!--        <label style="font-size: 11px;">{{ appBarData.role }}</label>-->
<!--      </div>-->
<!--      <b-nav-item-dropdown-->
<!--          right-->
<!--          toggle-class="d-flex align-items-center dropdown-user-link"-->
<!--          class="dropdown-user"-->
<!--      >-->
<!--        <template #button-content>-->
<!--          <lenon-profile-photo-->
<!--              size="40"-->
<!--              :photo="photo"-->
<!--              :preview="false"-->
<!--          />-->
<!--        </template>-->

<!--        <b-dropdown-item-->
<!--            link-class="d-flex align-items-center"-->
<!--            @click="$router.push({name:'profile'})"-->
<!--        >-->
<!--          <feather-icon-->
<!--              size="16"-->
<!--              icon="UserIcon"-->
<!--              class="mr-50"-->
<!--          />-->
<!--          <span>Profile</span>-->
<!--        </b-dropdown-item>-->

<!--        <b-dropdown-divider />-->
<!--        <b-dropdown-item-->
<!--            link-class="d-flex align-items-center"-->
<!--            @click="changeTheme"-->
<!--        >-->
<!--          <feather-icon-->
<!--              size="16"-->
<!--              icon="MoonIcon"-->
<!--              class="mr-50"-->
<!--              v-if="!isDarkMode"-->
<!--          />-->
<!--          <span v-if="!isDarkMode">Dark Mode</span>-->

<!--          <feather-icon-->
<!--              size="16"-->
<!--              icon="SunIcon"-->
<!--              class="mr-50"-->
<!--              v-if="isDarkMode"-->
<!--          />-->
<!--          <span v-if="isDarkMode">Light Mode</span>-->
<!--        </b-dropdown-item>-->

<!--        <b-dropdown-divider />-->
<!--        <b-dropdown-item-->
<!--            link-class="d-flex align-items-center"-->
<!--            @click="logout()"-->
<!--        >-->
<!--          <feather-icon-->
<!--              size="16"-->
<!--              icon="LogOutIcon"-->
<!--              class="mr-50"-->
<!--          />-->
<!--          <span>Logout</span>-->
<!--        </b-dropdown-item>-->
<!--      </b-nav-item-dropdown>-->
<!--    </b-navbar-nav>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import {-->
<!--  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BFormCheckbox-->
<!--} from 'bootstrap-vue'-->
<!--import logoutMixin from '@/lenon/mixins/logoutMixin'-->
<!--import {$themeConfig} from "/themeConfig"-->
<!--import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'-->
<!--export default {-->
<!--  components: {-->
<!--    LenonProfilePhoto,-->
<!--    BNavbarNav,-->
<!--    BNavItemDropdown,-->
<!--    BDropdownItem,-->
<!--    BDropdownDivider,-->
<!--    BAvatar,-->
<!--    BFormCheckbox-->
<!--  },-->
<!--  mixins: [logoutMixin],-->
<!--  props: {-->
<!--    toggleVerticalMenuActive: {-->
<!--      type: Function,-->
<!--      default: () => {-->
<!--      },-->
<!--    },-->
<!--  },-->
<!--  data(){-->
<!--    return{-->
<!--      isDarkMode: false,-->
<!--    }-->
<!--  },-->
<!--  watch:{-->
<!--    isDarkMode(darkmode){-->
<!--      let skin = "light"-->
<!--      if(darkmode){-->
<!--        skin = "dark"-->
<!--      }-->
<!--      this.$store.commit("appConfig/UPDATE_SKIN",skin)-->
<!--      if(skin==="dark"){-->
<!--        const body = document.getElementById("lenon-body-light")-->
<!--        if(body){-->
<!--          body.setAttribute("id","lenon-body-dark")-->
<!--        }-->
<!--      }else{-->
<!--        const body = document.getElementById("lenon-body-dark")-->
<!--        if(body){-->
<!--          body.setAttribute("id","lenon-body-light")-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  methods:{-->
<!--    changeTheme(){-->
<!--      this.isDarkMode = !this.isDarkMode-->
<!--    },-->
<!--    toggleSideMenu(){-->
<!--      if(document.getElementsByClassName("sidenav-overlay")[0].classList.contains("show")){-->
<!--        document.getElementsByClassName("sidenav-overlay")[0]-->
<!--            .setAttribute("class","sidenav-overlay")-->
<!--      }else{-->
<!--        document.getElementsByClassName("sidenav-overlay")[0].-->
<!--        setAttribute("class","sidenav-overlay show")-->
<!--      }-->
<!--      if(document.getElementsByClassName("vertical-layout")[0].classList.contains("menu-hide")){-->
<!--        document.getElementsByClassName("vertical-layout")[0]-->
<!--            .setAttribute("class","vertical-layout h-100 vertical-overlay-menu menu-open navbar-floating footer-static")-->
<!--      }else{-->
<!--        document.getElementsByClassName("vertical-layout")[0].-->
<!--        setAttribute("class","  vertical-layout h-100 vertical-overlay-menu menu-hide navbar-floating footer-static")-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    isSideBarHidden(){-->
<!--      return $themeConfig.layout.isSideBarHidden-->
<!--    },-->
<!--    isHome(){-->
<!--      return this.$route.name ==="home";-->
<!--    },-->
<!--    photo() {-->
<!--      return this.$store.getters['auth/userProfile']-->
<!--    },-->
<!--    logoUrl() {-->
<!--      return this.$store.getters['auth/schoolLogo']-->
<!--    },-->
<!--    appBarData() {-->
<!--      return this.$store.getters['auth/appBarData']-->
<!--    },-->
<!--    currentModule() {-->
<!--      let title = this.$store.getters['auth/currentModule']-->
<!--      if (this.$route.name === 'home') {-->
<!--        title = ''-->
<!--      }-->
<!--      return title-->
<!--    },-->
<!--  },-->
<!--  mounted() {-->
<!--    const skin = localStorage.getItem("vuexy-skin")-->
<!--    this.isDarkMode = skin === "dark"-->
<!--  }-->
<!--}-->
<!--</script>-->
