<template>
  <b-container class="col-lg-10 col-xl-8 pt-5">
    <b-navbar :class="`mb-3 ${isDarkMode?'lenon-nav-dark':''}`">
      <navbar />
    </b-navbar>
    <router-view />
    <!-- Footer -->
    <footer
      class="footer footer-light"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->
  </b-container>
</template>

<script lang="ts">
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { BNavbar, BContainer } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    AppFooter,
    BContainer,
    Navbar,
    BNavbar,
  },
  data() {
    return {}
  },
  computed: {
    isDarkMode() {
      const skin = this.$store.getters['appConfig/skin']
      return skin === 'dark'
    },
    schoolName() {
      return this.$store.getters['auth/schoolName'] || 'Lenon - A mobile first school management platform'
    },
    schoolLogo() {
      return this.$store.getters['auth/schoolLogo'] || '<%= BASE_URL %>logo.png'
    },
  },
  mounted() {
    const appTitle = document.getElementById('lenon-app-title')
    const old16 = document.querySelector("link[sizes*='16x16']")
    const old32 = document.querySelector("link[sizes*='32x32']")
    if (old16) {
      old16.setAttribute('type', 'image/x-icon')
      old16.setAttribute('rel', 'icon')
      old16.setAttribute('href', this.schoolLogo)
    }
    if (old32) {
      old32.setAttribute('type', 'image/x-icon')
      old32.setAttribute('rel', 'icon')
      old32.setAttribute('href', this.schoolLogo)
    }
    if (!old16 && !old32) {
      const link = document.createElement('link')
      link.setAttribute('type', 'image/x-icon')
      link.setAttribute('rel', 'icon')
      link.setAttribute('href', this.schoolLogo)
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    // link.id = 'lenon-app-logo'
    // if(old){
    //   document.getElementsByTagName('head')[0].removeChild(old)
    // }
    // document.getElementsByTagName('head')[0].appendChild(link)
    appTitle.innerText = this.schoolName
  },
}
</script>
<style lang="scss">
    footer.footer {
        padding: 0.8rem 0.8rem;
    }
    .lenon-nav-dark{
      background-color: #283046;
    }
</style>
